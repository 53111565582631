var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-form',{on:{"submit":function($event){$event.preventDefault();return _vm.submitResponse.apply(null, arguments)}}},[_c('p',{staticClass:"mb-3"},[_vm._v(" A mixture of acetone and heptane is used in this project to create the TLC eluent. Only a small amount of liquid "),_c('stemble-latex',{attrs:{"content":"$(2-3~\\text{mL})$"}}),_vm._v(" is needed for the development chamber, so the volume to volume ratios of heptane to acetone must be converted to reasonable quantities so that excess waste is not created. If the total volume is "),_c('stemble-latex',{attrs:{"content":"$2-3~\\text{mL,}$"}}),_vm._v(" fill in the table to show how much of each chemical is needed to make the ratio. ")],1),_c('table',{staticClass:"mb-3"},[_c('thead',[_c('tr',[_c('th',[_vm._v("Heptane:Acetone Ratio")]),_c('th',[_vm._v("Heptane Volume (mL)")]),_c('th',[_vm._v("Acetone Volume (mL)")])])]),_c('tbody',[_c('tr',[_c('td',{staticStyle:{"font-weight":"bold","text-align":"center"}},[_vm._v("1:1")]),_c('td',[_c('v-select',{staticClass:"ml-1",staticStyle:{"display":"inline-block","max-width":"200px"},attrs:{"items":_vm.items,"item-text":"text","item-value":"value","label":"A:"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}}]),model:{value:(_vm.inputs.input1),callback:function ($$v) {_vm.$set(_vm.inputs, "input1", $$v)},expression:"inputs.input1"}})],1),_c('td',[_c('v-select',{staticClass:"ml-1",staticStyle:{"display":"inline-block","max-width":"200px"},attrs:{"items":_vm.items,"item-text":"text","item-value":"value","label":"B:"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}}]),model:{value:(_vm.inputs.input2),callback:function ($$v) {_vm.$set(_vm.inputs, "input2", $$v)},expression:"inputs.input2"}})],1)]),_c('tr',[_c('td',{staticStyle:{"font-weight":"bold","text-align":"center"}},[_vm._v("1:2")]),_c('td',[_c('v-select',{staticClass:"ml-1",staticStyle:{"display":"inline-block","max-width":"200px"},attrs:{"items":_vm.items,"item-text":"text","item-value":"value","label":"C:"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}}]),model:{value:(_vm.inputs.input3),callback:function ($$v) {_vm.$set(_vm.inputs, "input3", $$v)},expression:"inputs.input3"}})],1),_c('td',[_c('v-select',{staticClass:"ml-1",staticStyle:{"display":"inline-block","max-width":"200px"},attrs:{"items":_vm.items,"item-text":"text","item-value":"value","label":"D:"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}}]),model:{value:(_vm.inputs.input4),callback:function ($$v) {_vm.$set(_vm.inputs, "input4", $$v)},expression:"inputs.input4"}})],1)]),_c('tr',[_c('td',{staticStyle:{"font-weight":"bold","text-align":"center"}},[_vm._v("2:1")]),_c('td',[_c('v-select',{staticClass:"ml-1",staticStyle:{"display":"inline-block","max-width":"200px"},attrs:{"items":_vm.items,"item-text":"text","item-value":"value","label":"E:"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}}]),model:{value:(_vm.inputs.input5),callback:function ($$v) {_vm.$set(_vm.inputs, "input5", $$v)},expression:"inputs.input5"}})],1),_c('td',[_c('v-select',{staticClass:"ml-1",staticStyle:{"display":"inline-block","max-width":"200px"},attrs:{"items":_vm.items,"item-text":"text","item-value":"value","label":"F:"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}}]),model:{value:(_vm.inputs.input6),callback:function ($$v) {_vm.$set(_vm.inputs, "input6", $$v)},expression:"inputs.input6"}})],1)]),_c('tr',[_c('td',{staticStyle:{"font-weight":"bold","text-align":"center"}},[_vm._v("3:2")]),_c('td',[_c('v-select',{staticClass:"ml-1",staticStyle:{"display":"inline-block","max-width":"200px"},attrs:{"items":_vm.items,"item-text":"text","item-value":"value","label":"G:"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}}]),model:{value:(_vm.inputs.input7),callback:function ($$v) {_vm.$set(_vm.inputs, "input7", $$v)},expression:"inputs.input7"}})],1),_c('td',[_c('v-select',{staticClass:"ml-1",staticStyle:{"display":"inline-block","max-width":"200px"},attrs:{"items":_vm.items,"item-text":"text","item-value":"value","label":"H:"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}}]),model:{value:(_vm.inputs.input8),callback:function ($$v) {_vm.$set(_vm.inputs, "input8", $$v)},expression:"inputs.input8"}})],1)])])]),_c('p',{staticClass:"mb-2"},[_vm._v(" The ratio between the solvents should be reasonably precise, but not as precise as when making standard solutions for a Beer's Law plot. What is the best piece of glassware to measure out the above volumes? ")]),_c('v-radio-group',{staticClass:"mb-0",attrs:{"disabled":!_vm.allowEditing},model:{value:(_vm.inputs.input9),callback:function ($$v) {_vm.$set(_vm.inputs, "input9", $$v)},expression:"inputs.input9"}},_vm._l((_vm.options),function(option){return _c('v-radio',{key:'pt-1-' + option.value,staticClass:"my-1 ml-3",attrs:{"value":option.value},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('stemble-latex',{attrs:{"content":option.text}})]},proxy:true}],null,true)})}),1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }