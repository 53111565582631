<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-3">
        A mixture of acetone and heptane is used in this project to create the TLC eluent. Only a
        small amount of liquid <stemble-latex content="$(2-3~\text{mL})$" /> is needed for the
        development chamber, so the volume to volume ratios of heptane to acetone must be converted
        to reasonable quantities so that excess waste is not created. If the total volume is
        <stemble-latex content="$2-3~\text{mL,}$" /> fill in the table to show how much of each
        chemical is needed to make the ratio.
      </p>

      <table class="mb-3">
        <thead>
          <tr>
            <th>Heptane:Acetone Ratio</th>
            <th>Heptane Volume (mL)</th>
            <th>Acetone Volume (mL)</th>
          </tr>
        </thead>

        <tbody>
          <tr>
            <td style="font-weight: bold; text-align: center">1:1</td>
            <td>
              <v-select
                v-model="inputs.input1"
                style="display: inline-block; max-width: 200px"
                class="ml-1"
                :items="items"
                item-text="text"
                item-value="value"
                label="A:"
              >
                <template #item="{item}">
                  <stemble-latex class="no-text-transform" :content="item.text" />
                </template>
                <template #selection="{item}">
                  <stemble-latex class="no-text-transform" :content="item.text" />
                </template>
              </v-select>
            </td>
            <td>
              <v-select
                v-model="inputs.input2"
                style="display: inline-block; max-width: 200px"
                class="ml-1"
                :items="items"
                item-text="text"
                item-value="value"
                label="B:"
              >
                <template #item="{item}">
                  <stemble-latex class="no-text-transform" :content="item.text" />
                </template>
                <template #selection="{item}">
                  <stemble-latex class="no-text-transform" :content="item.text" />
                </template>
              </v-select>
            </td>
          </tr>

          <tr>
            <td style="font-weight: bold; text-align: center">1:2</td>
            <td>
              <v-select
                v-model="inputs.input3"
                style="display: inline-block; max-width: 200px"
                class="ml-1"
                :items="items"
                item-text="text"
                item-value="value"
                label="C:"
              >
                <template #item="{item}">
                  <stemble-latex class="no-text-transform" :content="item.text" />
                </template>
                <template #selection="{item}">
                  <stemble-latex class="no-text-transform" :content="item.text" />
                </template>
              </v-select>
            </td>
            <td>
              <v-select
                v-model="inputs.input4"
                style="display: inline-block; max-width: 200px"
                class="ml-1"
                :items="items"
                item-text="text"
                item-value="value"
                label="D:"
              >
                <template #item="{item}">
                  <stemble-latex class="no-text-transform" :content="item.text" />
                </template>
                <template #selection="{item}">
                  <stemble-latex class="no-text-transform" :content="item.text" />
                </template>
              </v-select>
            </td>
          </tr>

          <tr>
            <td style="font-weight: bold; text-align: center">2:1</td>
            <td>
              <v-select
                v-model="inputs.input5"
                style="display: inline-block; max-width: 200px"
                class="ml-1"
                :items="items"
                item-text="text"
                item-value="value"
                label="E:"
              >
                <template #item="{item}">
                  <stemble-latex class="no-text-transform" :content="item.text" />
                </template>
                <template #selection="{item}">
                  <stemble-latex class="no-text-transform" :content="item.text" />
                </template>
              </v-select>
            </td>
            <td>
              <v-select
                v-model="inputs.input6"
                style="display: inline-block; max-width: 200px"
                class="ml-1"
                :items="items"
                item-text="text"
                item-value="value"
                label="F:"
              >
                <template #item="{item}">
                  <stemble-latex class="no-text-transform" :content="item.text" />
                </template>
                <template #selection="{item}">
                  <stemble-latex class="no-text-transform" :content="item.text" />
                </template>
              </v-select>
            </td>
          </tr>

          <tr>
            <td style="font-weight: bold; text-align: center">3:2</td>
            <td>
              <v-select
                v-model="inputs.input7"
                style="display: inline-block; max-width: 200px"
                class="ml-1"
                :items="items"
                item-text="text"
                item-value="value"
                label="G:"
              >
                <template #item="{item}">
                  <stemble-latex class="no-text-transform" :content="item.text" />
                </template>
                <template #selection="{item}">
                  <stemble-latex class="no-text-transform" :content="item.text" />
                </template>
              </v-select>
            </td>
            <td>
              <v-select
                v-model="inputs.input8"
                style="display: inline-block; max-width: 200px"
                class="ml-1"
                :items="items"
                item-text="text"
                item-value="value"
                label="H:"
              >
                <template #item="{item}">
                  <stemble-latex class="no-text-transform" :content="item.text" />
                </template>
                <template #selection="{item}">
                  <stemble-latex class="no-text-transform" :content="item.text" />
                </template>
              </v-select>
            </td>
          </tr>
        </tbody>
      </table>

      <p class="mb-2">
        The ratio between the solvents should be reasonably precise, but not as precise as when
        making standard solutions for a Beer's Law plot. What is the best piece of glassware to
        measure out the above volumes?
      </p>
      <v-radio-group v-model="inputs.input9" :disabled="!allowEditing" class="mb-0">
        <v-radio
          v-for="option in options"
          :key="'pt-1-' + option.value"
          class="my-1 ml-3"
          :value="option.value"
        >
          <template #label>
            <stemble-latex :content="option.text" />
          </template>
        </v-radio>
      </v-radio-group>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '../StembleLatex.vue';

export default {
  name: 'ChemUCI1LDQ2S1Q1',
  components: {
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        input1: null,
        input2: null,
        input3: null,
        input4: null,
        input5: null,
        input6: null,
        input7: null,
        input8: null,
        input9: null,
      },
      items: [
        {text: '$0.5$', value: 'half'},
        {text: '$1$', value: 'one'},
        {text: '$1.5$', value: 'oneAndAHalf'},
        {text: '$2$', value: 'two'},
      ],
      options: [
        {text: 'volumetric pipet', value: 'vol'},
        {text: 'graduated cylinder', value: 'grad'},
        {text: 'beaker', value: 'beaker'},
        {text: 'disposable pipet', value: 'disPipet'},
        {text: 'Erlenmeyer flask', value: 'erl'},
      ],
    };
  },
};
</script>
<style scoped>
table {
  border-collapse: collapse;
}

td,
th {
  border-style: solid;
  border-width: 1px;
  padding: 5px;
}
</style>
