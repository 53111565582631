import { render, staticRenderFns } from "./ChemUCI1LDQ2S1Q1.vue?vue&type=template&id=000a5d00&scoped=true&"
import script from "./ChemUCI1LDQ2S1Q1.vue?vue&type=script&lang=js&"
export * from "./ChemUCI1LDQ2S1Q1.vue?vue&type=script&lang=js&"
import style0 from "./ChemUCI1LDQ2S1Q1.vue?vue&type=style&index=0&id=000a5d00&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "000a5d00",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VForm } from 'vuetify/lib/components/VForm';
import { VRadio } from 'vuetify/lib/components/VRadioGroup';
import { VRadioGroup } from 'vuetify/lib/components/VRadioGroup';
import { VSelect } from 'vuetify/lib/components/VSelect';
installComponents(component, {VForm,VRadio,VRadioGroup,VSelect})
